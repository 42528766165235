import { useCallback, useContext } from "react";
import { RUMContext } from "../rum/rum-context";
import { matchPath } from "react-router-dom";
import { QueryStatus, skipToken } from "@reduxjs/toolkit/query";
import { useGetCandidateDataQuery } from "../reduxStore/api/apiSlice";
import { StatusEnum } from "../utility/enums/common";
import { ALL_URLS } from "../config/urls";
import { ApplicationManageData, JobDetailsModel } from "../utility/application-data";

// We specify event types to make it easy to query cloudwatch log insights
type AshEventType = `ash_${"info" | "error"}_${string}`;
interface BaseRumEvent {
  // To allow prefix matching insights rules, we force all events to identify as error or info
  type: AshEventType;
}

interface AuthRedirectEvent extends BaseRumEvent {
  type: "ash_info_auth_redirect";
  fromUrl: string;
}

interface AtoZErrorEvent extends BaseRumEvent {
  type: "ash_error_a2z";
  timestamp: number;
  scheduleDetails?: JobDetailsModel;
  applicationManageData?: ApplicationManageData;
}

interface EdmPortalRedirectEvent extends BaseRumEvent {
  type: "ash_info_edm_portal_redirect_event";
  edmLoginUrl: string;
  from: "employeedocs" | "workauth";
}

interface FailedFetchForAtoZEvent extends BaseRumEvent {
  type: "ash_error_failed_fetch_data_for_AtoZ";
  taskName: string;
  isError: boolean;
  error: unknown;
  fetchStatus: QueryStatus;
}

interface GetStatusRequestFailEvent extends BaseRumEvent {
  type: "ash_error_get_status_request_fail";
  taskName?: string;
  message?: string;
}

interface GetTaskStatusErrorEvent extends BaseRumEvent {
  type: "ash_error_get_task_status";
  issue: "404" | "missing" | "other";
  taskStatusName: string;
  timestamp: number;
}

interface ZapposCodeMissingEvent extends BaseRumEvent {
  type: "ash_error_zappos_code_missing";
  taskStatus: StatusEnum; // TODO: migrate to API Common version
}

interface ZapposRedirectEvent extends BaseRumEvent {
  type: "ash_info_zappos_redirect_event";
  taskId: string;
}

interface ReduxUpdatePassingThresholdEvent extends BaseRumEvent {
  type: "ash_error_redux_update_passing_threshold";
  message: string;
}

interface ReactMemoryLeakEvent extends BaseRumEvent {
  type: "ash_error_react_memory_leak";
  message: string;
}

interface PacePageLoadFailureEvent extends BaseRumEvent {
  type: "ash_error_page_load_failure";
  message: string;
  error: unknown;
}

interface PaceApplicationErrorEvent extends BaseRumEvent {
  type: "ash_error_application_error";
  errorMessage?: string;
  errorCode?: string;
  errorStatusCode?: number;
}

export type AshRumEvent =
  | AuthRedirectEvent
  | AtoZErrorEvent
  | EdmPortalRedirectEvent
  | FailedFetchForAtoZEvent
  | GetStatusRequestFailEvent
  | GetTaskStatusErrorEvent
  | ZapposCodeMissingEvent
  | ZapposRedirectEvent
  | ReduxUpdatePassingThresholdEvent
  | ReactMemoryLeakEvent
  | PacePageLoadFailureEvent
  | PaceApplicationErrorEvent;

const REDIRECT_EVENTS: readonly AshEventType[] = [
  "ash_info_auth_redirect",
  "ash_info_edm_portal_redirect_event",
  "ash_info_zappos_redirect_event",
] as const;

type UseAshRumResult = {
  /**
   * Record an event with automatic metadata
   * @param event An ASH rum event to record
   */
  recordRumEvent: (event: AshRumEvent) => void;
};

// Need to copy as ALL_URLS is `readonly as const`
const ASH_ROUTES = [...ALL_URLS];

function detectRoute(pathname: string): { applicationId?: string; requisitionId?: string } {
  const match = matchPath<{ applicationId?: string; requisitionId?: string }>(pathname, {
    path: ASH_ROUTES,
    exact: true, // Matches logic in real app router
  });

  return {
    applicationId: match?.params?.applicationId,
    requisitionId: match?.params?.requisitionId,
  };
}

/**
 * Automatically handles attaching candidate id, job id, and application id metadata to events
 *
 * This is done on a best-effort basis, some core RUM events like session_start will emit before this loads
 */
export const useAshRum = (): UseAshRumResult => {
  /**
   * Be really careful here, if we break this code we'll lose the info that tells us if ASH is having issues!
   *
   * Avoid depending on anything else in the app or complex logic, assume anything can be null/undefined
   *
   * This is used in AuthOrRedirect, so we can't use the `useParams` hook as the router is a child of that component
   *
   * We use API slice directly to avoid circular dependency on useConfig
   */
  const rumClient = useContext(RUMContext);

  // Try to get basic data automatically from route, these may be undefined / invalid if the URL is wrong
  const { applicationId, requisitionId } = detectRoute(window.location.pathname);
  // No gurantee this is even a valid URL or application, be ready for an error response
  const { data: candidateData } = useGetCandidateDataQuery(applicationId ? { applicationId } : skipToken) ?? {};

  // We have to attach attributes separately, RUM does not overwrite the placeholder values
  // Note: RUM has a hard limit of 10 custom attributes shared between session and event
  if (rumClient && applicationId) {
    rumClient.addSessionAttributes({
      applicationId,
    });
  }

  if (rumClient && requisitionId) {
    rumClient.addSessionAttributes({
      jobId: requisitionId,
    });
  }

  if (rumClient && candidateData) {
    const { candidateId, sfCandidateId, candidateGlobalId } = candidateData;

    if (candidateId) {
      rumClient.addSessionAttributes({
        candidateId,
      });
    }

    if (sfCandidateId) {
      rumClient.addSessionAttributes({
        sfCandidateId,
      });
    }

    if (candidateGlobalId) {
      rumClient.addSessionAttributes({
        candidateGlobalId,
      });
    }
  }

  // Avoid forcing recompute for useEffect and such that use recordRumEvent
  const recordRumEvent: (event: AshRumEvent) => void = useCallback(
    // If someone messes an event `type` field this will generate compiler error so they fix it
    ({ type, ...data }: { type: AshEventType }) => {
      rumClient?.recordEvent(type, data);

      if (REDIRECT_EVENTS.includes(type)) {
        // Flush events before page unload, use beacon since normal dispatch may cancel on unload
        rumClient?.dispatchBeacon();
      }
    },
    [rumClient]
  );

  return { recordRumEvent };
};
