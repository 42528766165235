import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import React from "react";
import { useChecklistArb } from "../../hooks/use-arb";
import { useNavigatorWithURLReadyOnOpeningNewTab } from "../../hooks/use-navigator";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { StatusEnum } from "../../utility/enums/common";
import { BadgePhotoProps } from "./badgePhotoInTaskcardFlyoutDetails";

export const BadgePhotoTaskCardButton = ({ status, shouldOverrideStatus }: BadgePhotoProps) => {
  const bundle = useChecklistArb();
  const translatedUpload = bundle.getMessage("Checklist-BPUpload-Button");
  const translatedReplace = bundle.getMessage("Checklist-BPUpload-Replace");

  const overrideStatus = shouldOverrideStatus ? shouldOverrideStatus[status] : status;
  const buttonText = overrideStatus === StatusEnum.ACTION_REQUIRED ? translatedUpload : translatedReplace;

  const { badgePhotoStartURL } = useUrlBuilder();

  return (
    <Button
      data-testid="badge-photo-upload-or-replace-button"
      variant={ButtonVariant.Primary}
      onClick={useNavigatorWithURLReadyOnOpeningNewTab(badgePhotoStartURL)}
      disabled={status === StatusEnum.COMPLETED}
    >
      {buttonText}
    </Button>
  );
};
