/* eslint-disable import/no-unused-modules */ // This is the application entry point
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { get as getCookie } from "js-cookie";
import { AuthOrRedirect } from "./components/auth/redirect";
// Import build tool generated manifest file
import arbManifest from "../public/translations/arbManifest";
import { createAsyncMessageBundleManager, LocalizationContextBuilder } from "@amzn/arb-tools";
import { MbmProvider } from "@amzn/react-arb-tools";
import { Provider } from "react-redux";
import { setupStore } from "./reduxStore/store";
import { changeHtmlLangAttribute, getLocale } from "./utility/locale-helper";
import { RUMContext } from "./rum/rum-context";
import { formatMessage } from "./utility/message-format";
import { initAwsRumClient } from "./rum";

class ResourceFetchError extends Error {
  constructor(message: string, public retry: boolean) {
    super(message);
  }
}

async function main() {
  // register msw to mock browser api
  if (process.env.NODE_ENV === "development" && !getCookie("ash-hydra-country")) {
    if (window.location.pathname === "/checklist") {
      window.location.pathname = "/checklist/";
      return;
    }

    const { worker } = require("./mocks/browser");

    await worker.start({
      serviceWorker: {
        url: "/checklist/mockServiceWorker.js",
      },
    });
  }

  const rumClient = await initAwsRumClient();

  // Intercept console error messages and report them. Uses custom property to avoid infinite wrapping
  if (rumClient && !(console.error as { ash_wrapped?: boolean }).ash_wrapped) {
    const err = console.error;

    console.error = function (...args: unknown[]) {
      // Still print to console to allow local debugging
      err.apply(console, args);
      // Submit an error event to RUM with the message for analysis
      rumClient!.recordError(
        new ErrorEvent("console_error", {
          message: formatMessage(args),
        })
      );
    };

    (console.error as { ash_wrapped?: boolean }).ash_wrapped = true;
  }

  const store = setupStore({});

  // Define a localization context provider
  const localizationContextProvider = () =>
    new LocalizationContextBuilder().withLocale(getLocale()).withDefaultLocale("en-US").build();

  const mbm = createAsyncMessageBundleManager({
    arbManifest,
    localizationContextProvider,
    resolveBundleUrl: (url: string | undefined) =>
      url ? "/checklist/translations/" + url : "/checklist/translations/translation.json",
    // Override the cache control behavior, this is otherwise an exact copy of the default fetch logic in ARBtools
    resolveResourcePack: async (url) => {
      const response = await fetch(url, { headers: { "Cache-Control": "no-store" } });
      const { ok, status, statusText } = response;
      if (!ok) {
        const retry = status >= 500 && status < 600; // don't retry for 4xx codes
        console.error(`Failed to fetch ${url}: ${{ status, statusText }}`);
        throw new ResourceFetchError(`Failed to fetch ${url}`, retry);
      }
      return response.json();
    },
  });

  ReactDOM.render(
    <RUMContext.Provider value={rumClient}>
      <Provider store={store}>
        <MbmProvider mbm={mbm}>
          <React.StrictMode>
            <AuthOrRedirect>
              <App />
            </AuthOrRedirect>
          </React.StrictMode>
        </MbmProvider>
      </Provider>
    </RUMContext.Provider>,
    document.getElementById("ASHContentRoot")
  );
}

changeHtmlLangAttribute();
main();
