import {
  ApplicationStatesConfiguration,
  FlagConfiguration,
  SimpleConfiguration,
  TaskDefinitionConfiguration,
} from "@amzn/hvh-simple-hire-checklist-app-config-common";
import {
  CUSTOMER_SERVICE,
  FeatFlag,
  FeatureFlag,
  IS_DISABLE_I9_GET_CALL,
  IS_GLOBAL_ID_ENABLED,
  IS_DRUG_TEST_APPOINTMENT_ENABLED,
  IS_NHE_VENUE_TIME_ENABLED,
  NON_CUSTOMER_SERVICE,
  REQUISITION_CATEGORY,
  RIGHT_TO_WORK_TASK_DEFINITION_ID,
  RIGHT_TO_WORK_TASK_TITLE,
  SUPPORTED_COUNTRY,
  TASK_DEFINITION_CONFIG,
  TASK_FEATURE_FLAG_CONFIG,
  IS_RESCHEDULE_ALLOWED_PAST_APPT_TIME,
} from "../config/appConfigConstants";
import { JobDetailsModel } from "./application-data";
import { load, save } from "./local-storage";
import { getDefaultLocaleAndCountryForCurrentDomain, getCountryForDomain } from "./locale-helper";
import { AppConfigResponse } from "./types/common";

// Typescript won't infer type for us becuse it is not 100% safe. Without AppConfig schema someone could build a bad object with the wrong configurationType
type SimpleConfigWithoutType = SimpleConfiguration & { configurationType: undefined };
type UnsafeConfig =
  | ApplicationStatesConfiguration
  | FlagConfiguration
  | TaskDefinitionConfiguration
  | SimpleConfigWithoutType;

export interface CandidateData {
  candidateId: string;
  sfCandidateId: string;
}

export async function processConfigurations(
  appConfig: AppConfigResponse,
  applicationId: string,
  scheduleDetails: JobDetailsModel
) {
  if (!appConfig || !appConfig.configurations || !appConfig.configurations.configurationsArray) {
    //no valid AppConfig or no valid format found for AppConfig
    return;
  }

  if (scheduleDetails.requisitionCategory) {
    save(REQUISITION_CATEGORY + applicationId, scheduleDetails.requisitionCategory);
  }

  for (const sourceConfig of appConfig.configurations.configurationsArray) {
    const config: UnsafeConfig = sourceConfig as UnsafeConfig;
    const configName = config.configurationName;
    if (!config.configurationName) {
      continue;
    }
    const configType = config.configurationType;
    //saves into local storage

    if (configType == "application_states_config") {
      // Not used in frontend right now
      continue;
    }

    if (configType == TASK_FEATURE_FLAG_CONFIG) {
      await setFeatureFlagForTaskList(applicationId, config, scheduleDetails);
    } else if (configType == TASK_DEFINITION_CONFIG) {
      save(config.taskId + applicationId, config.definitions);
    } else if (configName == IS_GLOBAL_ID_ENABLED) {
      save(IS_GLOBAL_ID_ENABLED, config.defaultValue);
    } else if (configName == IS_DISABLE_I9_GET_CALL) {
      save(IS_DISABLE_I9_GET_CALL, config.defaultValue);
    } else if (configName == FeatFlag.ENABLE_ALL_CONTINGENCIES_BGC) {
      save(configName, config.defaultValue);
    } else if (configName == FeatFlag.ENABLE_ALL_CONTINGENCIES_AIR_BGC) {
      save(configName, config.defaultValue);
    } else if (configName == FeatFlag.ENABLE_ALL_CONTINGENCIES_DT) {
      save(configName, config.defaultValue);
    } else if (configName == FeatFlag.ENABLE_ALL_CONTINGENCIES_AIR_DT) {
      save(configName, config.defaultValue);
    } else if (configName == FeatFlag.ENABLE_ALL_CONTINGENCIES_RTW) {
      save(configName, config.defaultValue);
    } else if (configName == FeatFlag.ENABLE_ALL_CONTINGENCIES_WORK_AUTH) {
      save(configName, config.defaultValue);
    } else if (configName == FeatFlag.ENABLE_ALL_CONTINGENCIES_MEDICAL_CHECK) {
      save(configName, config.defaultValue);
    } else if (configName == IS_NHE_VENUE_TIME_ENABLED) {
      save(IS_NHE_VENUE_TIME_ENABLED, config.defaultValue);
    } else if (configName == IS_RESCHEDULE_ALLOWED_PAST_APPT_TIME) {
      save(IS_RESCHEDULE_ALLOWED_PAST_APPT_TIME, config.defaultValue);
    }
  }
}

export async function setFeatureFlagForTaskList(applicationId: string, config: any, jobDetails: JobDetailsModel) {
  if (jobDetails.error) {
    save(config.taskId + applicationId, false);
    return;
  }

  if (!config.overrideConditions) {
    save(config.taskId + applicationId, config.defaultValue);
    return;
  }

  const countryCode = getDefaultLocaleAndCountryForCurrentDomain().country;
  const siteId = jobDetails.siteId;
  const businessLine = jobDetails.businessLine;
  const jobId = jobDetails.jobId;
  const requisitionCategory = jobDetails.requisitionCategory;
  let overrideValue = undefined;
  for (const condition of config.overrideConditions) {
    if (!isConfigMatched(condition.countryList, countryCode, "countryList")) {
      continue;
    }
    if (!isConfigMatched(condition.siteList, siteId, "siteId")) {
      continue;
    }
    if (!isConfigMatched(condition.businessLineList, businessLine, "businessLine")) {
      continue;
    }
    if (!isConfigMatched(condition.jobIdList, jobId, "jobId")) {
      continue;
    }
    if (!isConfigMatched(condition.requisitionCategoryList, requisitionCategory, "requisitionCategory")) {
      continue;
    }

    overrideValue = condition.overrideValue;
    break; //found a matched entry in appConfig to override default config
  }

  const taskEnabled = overrideValue != undefined ? overrideValue : config.defaultValue;
  save(config.taskId + applicationId, taskEnabled);
}

export function isConfigMatched(configList?: string[], key?: string, configListName?: string): boolean {
  let matched = false;
  if (!configList || configList.length == 0) {
    matched = true;
  } else if (key) {
    matched = configList.includes(key);
  }
  return matched;
}

export function isTaskEnabledByAppConfig(taskId: string, applicationId: string) {
  return load(taskId + applicationId) as boolean;
}

export function getTaskDefinitionsFromConfig(taskId: string, applicationId?: string) {
  if (!applicationId) {
    return undefined;
  }
  try {
    // TODO: will be removed later when integration with Deloittle is ready.
    const country = getCountryForDomain();
    if (
      taskId === RIGHT_TO_WORK_TASK_DEFINITION_ID &&
      country !== SUPPORTED_COUNTRY.US &&
      isCustomerService(applicationId)
    ) {
      const task = load(taskId + applicationId);
      return { ...task, isIntegratedWithCaaS: false };
    }

    return load(taskId + applicationId);
  } catch (e) {
    console.info(`No task definition for taskId: ${taskId} and applicationId: ${applicationId}`);
    return undefined;
  }
}

export function isCustomerService(applicationId: string) {
  return load(REQUISITION_CATEGORY + applicationId) == CUSTOMER_SERVICE;
}

export function getJobType(applicationId: string) {
  if (isCustomerService(applicationId)) {
    return CUSTOMER_SERVICE;
  }

  return NON_CUSTOMER_SERVICE;
}

export function getRightToWorkOrI9TaskType(taskTitle: string, applicationId: string) {
  return getTaskDefinitionsFromConfig(taskTitle, applicationId)?.taskTitle == RIGHT_TO_WORK_TASK_TITLE;
}

export function isGlobalIdEnabledByAppConfig() {
  return load(IS_GLOBAL_ID_ENABLED);
}

export function isNheVenueTimeEnabledByAppConfig() {
  return load(IS_NHE_VENUE_TIME_ENABLED);
}

export function isRescheduleAllowedPastApptTimeByAppConfig() {
  return load(IS_RESCHEDULE_ALLOWED_PAST_APPT_TIME);
}

export function isDisableCaasI9GETCallEnabledByAppConfig() {
  return load(IS_DISABLE_I9_GET_CALL);
}

export function isFlagEnabledByAppConfig(flag: FeatureFlag): boolean {
  return load(flag);
}

// TODO: remove fromDocUpload later when AppConfig is ready.
export const getASHConfigurations = async (
  applicationId: string,
  scheduleDetails: JobDetailsModel,
  appConfig: AppConfigResponse
) => {
  try {
    await processConfigurations(appConfig, applicationId, scheduleDetails);

    return { successfullyLoaded: true };
  } catch (e) {
    if (e instanceof Error) {
      console.error(
        `Failed to call AWS AppConfig. Applying default value for all ASH Configs. Error message ${e.message}`
      );

      return { successfullyLoaded: false };
    }
  }
};
