import {
  Brightness,
  FaceDetection,
  ImageCropper,
  ImageMinSize,
  ImageProcessingData,
  ImageProcessor,
  ImageResizer,
} from "@amzn/hvh-camera-ui";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SkipBadgeModal } from "../../../../components/skip-badge/modal";
import { H2, Text } from "../../../../components/typography";
import { Uploader } from "../../../../components/uploader";
import { HVH_COLORS } from "../../../../config/palette";
import { CHECKLIST_URL, TAKE_BADGE_PHOTO_CONFIRM_URL } from "../../../../config/urls";
import { incrementFailedAttempts, reachedAttemptsLimit } from "../../../../helpers/badge-failed-attempts";
import { useSendAnalyticEvent } from "../../../../hooks/jobDetailsCardAnalyticEventHelper.hook";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { useMetrics } from "../../../../hooks/use-metrics";
import { useNavigator } from "../../../../hooks/use-navigator";
import { ActionType } from "../../../../reactContextStore/state";
import { Context } from "../../../../reactContextStore/store";
import { extractMetricData } from "../../../../utility/badge-metric";
import { ChecklistPantherKey } from "../../../../utility/types/translations";
import "./styles.scss";

const modules = [
  new FaceDetection({
    noFaceIndicator: "Checklist-BPFaceDetection-NoFace",
    manyFacesIndicator: "Checklist-BPFaceDetection-MorePerson",
    modelsPath: "/checklist/models",
  }),
  new ImageCropper({ indicator: "Checklist-BPFaceDetection-TooClose" }),
  new ImageResizer({
    targetSize: { width: 600, height: 800 },
    indicator: "Checklist-BPImage-PoorQuality",
  }),
  new Brightness({
    threshold: { min: 10, max: 150 },
    indicator: "Checklist-BPFaceDetection-Brightness2",
  }),
  new ImageMinSize({
    minSize: { width: 240, height: 320 },
    indicator: "Checklist-BPFaceDetection-FullFace",
  }),
];

interface Result {
  finished: boolean;
  errors?: React.ReactNode[];
  imageProcessingResult?: ImageProcessingData;
}

const imageProcessor = new ImageProcessor(modules);

export const BadgeUploadRoute = () => {
  const { sendBadgePhotoPageAnalyticsEvent, sendBadgePhotoEventAnalyticsEvent } = useSendAnalyticEvent();

  const [goToConfirm, goToChecklist] = useNavigator(TAKE_BADGE_PHOTO_CONFIRM_URL, CHECKLIST_URL);
  const { dispatch } = useContext(Context);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [result, setResult] = useState<Result>({ finished: false });
  const [isSkipBadgeModalOpen, setIsSkipBadgeModalOpen] = useState(false);
  const [isInvalidImgStatus, setInvalidImgStatus] = useState<boolean>(false);
  const metrics = useMetrics();

  const closeSkipBadgeModal = useCallback(() => {
    setIsSkipBadgeModalOpen(false);
  }, []);

  useEffect(() => {
    sendBadgePhotoPageAnalyticsEvent("badge/upload");
  }, []);

  useEffect(() => {
    imageProcessor.init().then(() => setLoading(false));
    if (metrics?.sendMetric) {
      metrics.sendMetric({ pageView: "badge/upload" });
    }
  }, [metrics?.sendMetric]);

  const tryAnother = useCallback(() => {
    setIndex(index + 1);
    setResult({
      finished: false,
    });
  }, [index]);

  const checkImage = useCallback(
    async (imageData: string | null) => {
      if (!imageData) {
        setInvalidImgStatus(true);
        tryAnother();
        return;
      }
      setInvalidImgStatus(false);
      setInProgress(true);

      const imageProcessingResult = await imageProcessor.process(imageData);
      const metricData = extractMetricData(imageProcessingResult);
      if (metrics?.sendMetric) {
        metrics.sendMetric({ event: "image-upload", details: metricData });
      }

      setInProgress(false);

      if (process.env.NODE_ENV === "development") {
        console.log(imageProcessingResult);
      }
      if (!imageProcessingResult.success) {
        const { failedModule } = imageProcessingResult;
        incrementFailedAttempts();
        reachedAttemptsLimit() && setIsSkipBadgeModalOpen(true);
        setResult({
          finished: true,
          errors: [failedModule?.module.getIndicator(failedModule.results)],
        });
      } else {
        setResult({
          finished: true,
          imageProcessingResult,
        });
      }
    },
    [isInvalidImgStatus, inProgress, tryAnother, metrics?.sendMetric]
  );

  const submitImage = useCallback(() => {
    sendBadgePhotoEventAnalyticsEvent("upload select image");

    dispatch({
      type: ActionType.UpdateSelectedShot,
      payload: result.imageProcessingResult,
    });
    goToConfirm();
  }, [dispatch, goToConfirm, result]);

  const bundle = useChecklistArb();
  if (loading === true) {
    return (
      <Col justifyContent="center" alignItems="center" height="100vh">
        <Spinner size={SpinnerSize.Large}></Spinner>
      </Col>
    );
  }

  const banner = bundle.getMessage("Checklist-BPUpload-Banner");
  const error = bundle.getMessage("Checklist-BPUpload-ErrorMessage");
  const notAccepted = bundle.getMessage("Checklist-BPUpload-NotAccepted");
  const retry = bundle.getMessage("Checklist-BPUpload-Retry");
  const reUpload = bundle.getMessage("Checklist-BPUpload-ReUpload");
  const select = bundle.getMessage("Checklist-BPUpload-SelectButtonText");

  const errorSection =
    result.errors &&
    result.errors.map((error, i) => {
      let message = error && error.toString();
      if (message) {
        // It is really bad to cast the string as a key because we lose type check to ensure we have
        // a valid translation, but the errors come from a different package so this is hard to fix
        message = bundle.getMessage(message as ChecklistPantherKey); // TODO find better way to handle errors
      }
      return <Text key={i}>- {message}</Text>;
    });

  const showErrorSection = !inProgress && errorSection;
  const showSubmitButton = !inProgress && result.finished && result.imageProcessingResult;

  return (
    <>
      <PageContainer color={HVH_COLORS.NEUTRAL_90}>
        <Col gridGap="1rem">
          <MessageBanner isDismissible={false} type={MessageBannerType.Informational}>
            {banner}
          </MessageBanner>
          <Uploader data-testid="badge-photo-check-image" key={index} onUpload={checkImage} />
          {isInvalidImgStatus && (
            <MessageBanner isDismissible={false} type={MessageBannerType.Error}>
              {error}
            </MessageBanner>
          )}
          {inProgress && <Spinner />}
          {showErrorSection && (
            <Col gridGap="0.5rem">
              <H2>{notAccepted}</H2>
              {errorSection}
              <Row justifyContent="center">
                <Button data-testid="badge-photo-try-another-image-button" onClick={tryAnother}>
                  {retry}
                </Button>
              </Row>
            </Col>
          )}
          {showSubmitButton && (
            <Row justifyContent="space-around" width="100%" flexWrap="wrap" margin="0 0 0.5rem 0">
              <Button data-testid="badge-photo-try-another-image-button" onClick={tryAnother}>
                {reUpload}
              </Button>
              <Button
                data-testid="badge-photo-select-image-button"
                variant={ButtonVariant.Primary}
                onClick={submitImage}
              >
                {select}
              </Button>
            </Row>
          )}
        </Col>
        <SkipBadgeModal
          data-testid="badge-photo-close-skip-badge-modal"
          isOpen={isSkipBadgeModalOpen}
          close={closeSkipBadgeModal}
          goToChecklist={goToChecklist}
        />
      </PageContainer>
    </>
  );
};
