import { Col, Flex } from "@amzn/stencil-react-components/layout";
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";
import React from "react";
import { useParams } from "react-router-dom";
import { AlumniMessageBanner } from "../../components/AlumniBanner";
import { AppointmentCard } from "../../components/appointment-card";
import AppLoader from "../../components/common/AppLoader";
import { Gratitude } from "../../components/gratitude";
import { ManageApplicationCard } from "../../components/jobDetailsCard";
import { NewHireVideoFlyout } from "../../components/new-hire-video/NewHireVideo";
import { ScreeningResults } from "../../components/screening-results/screeningResults";
import { TaskGroupAggregator } from "../../components/task";
import { HVH_COLORS } from "../../config/palette";
import { GetChecklistTasks, getTargetTaskGroup, hideTaskOnConfig, TaskGroup } from "../../config/tasks";
import { buildUrl } from "../../config/urls";
import { Stage } from "../../helpers/get-settings";
import { isDragonstone } from "../../helpers/url-params";
import {
  badgePhotoAllowedStateForJapan,
  hasSkipAppointmentOneEligibility,
  isAlumni,
  isValidToShowGratitudeComponent,
  validStatesToShowScreeningResultForHBAApplications,
} from "../../helpers/utils";
import { useGetApplicationManageData } from "../../hooks/apis/useGetApplicationManageData";
import { useChecklistArb } from "../../hooks/use-arb";
import { useConfig } from "../../hooks/useConfig";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData } from "../../reduxStore/slices/configSlice";
import { isCustomerService } from "../../utility/app-config-helper";
import { getCountryForDomain } from "../../utility/locale-helper";
import ApplicationErrorBanner from "../../components/common/ApplicationErrorBanner";
import { useAshRum } from "../../hooks/useAshRum.hook";
import { createGroup } from "../../utility/taskGroup";

interface ChecklistRouteProps {
  stage?: Stage;
  isNewHireVideoRoute?: boolean;
}

export interface rtwAppointmentProps {
  isRtwAppointmentEnabled?: boolean;
  PreviousrightToWorkExpired: boolean | undefined;
}

export const ChecklistRoute = ({ stage, isNewHireVideoRoute }: ChecklistRouteProps) => {
  const { rtwAppointmentInfo, gratitudeComponentState, caasI9Status, caasRTWStatus, appError } =
    useAppSelector(getConfigData);
  const { applicationId, requisitionId, candidateId } = useParams<{
    applicationId: string;
    requisitionId: string;
    candidateId: string;
  }>();
  const { recordRumEvent } = useAshRum();

  const country = getCountryForDomain();
  const { appConfigLoaded, isConfigLoaded, isErrorLoadingConfig } = useConfig();

  if (isErrorLoadingConfig) {
    throw new Error("Have an error occured when loading initial data");
  }

  const applicationManageData = useGetApplicationManageData();

  const skipAppointmentOneEligiblilty = hasSkipAppointmentOneEligibility(applicationManageData);
  const enableBadgePhoto = badgePhotoAllowedStateForJapan(applicationManageData);
  const isAlumniCandidate = isAlumni(applicationManageData);
  const bundle = useChecklistArb();

  const {
    matches: { m: isMediumViewport, s: isSmallViewport },
  } = useBreakpoints();
  const isLargeViewport = !isSmallViewport && !isMediumViewport;

  const isEligibleForI9DocInsteadRTW = caasI9Status !== "SKIPPED";

  const { taskGroupForCustomerService, taskGroupForNewUI, taskGroupForSkipAppt1Eligible, matchingConfig } =
    GetChecklistTasks({
      bundle,
      applicationId,
      appConfigLoaded,
      country,
      rightToWorkAppointmentProps: rtwAppointmentInfo,
      workAuthStatus: isEligibleForI9DocInsteadRTW ? caasI9Status : caasRTWStatus,
      skipAppt1: skipAppointmentOneEligiblilty,
      enableBadgePhotoForJapan: enableBadgePhoto,
      isAlumniApplication: isAlumniCandidate,
    });

  //Todo Remove below code once the new config is working as expected
  const targetTaskGroup = getTargetTaskGroup({
    isCustomerService: isCustomerService(applicationId),
    isDragonstone: isDragonstone(requisitionId),
    skipAppointmentOne: skipAppointmentOneEligiblilty,
    taskGroups: {
      taskGroupForCustomerService,
      taskGroupForNewUI,
      taskGroupForSkipAppt1Eligible,
    },
  });

  const isDragonStonePage = isDragonstone(requisitionId) || isCustomerService(applicationId);
  const groups = targetTaskGroup.map((taskGroup) => {
    return {
      ...taskGroup,
      tasks: taskGroup.tasks.filter(hideTaskOnConfig).map((task) => ({
        ...task,
        titleStatusPath:
          task.titleStatusPath &&
          buildUrl(task.titleStatusPath, {
            stage,
            requisitionId,
            applicationId,
            candidateId,
          }),
      })),
    };
  });

  const { preNheTasks, preTrainingTasks, preDayOneTasks } = matchingConfig.overrideValue;

  const newTaskGroups = new Array(3);

  [preNheTasks, preTrainingTasks, preDayOneTasks].filter(Boolean).forEach((taskGroup) => {
    newTaskGroups.push(createGroup(taskGroup, stage, requisitionId, applicationId, candidateId));
  });

  // Something above here is secretly calling a hook from a non-hook function
  const gratitude =
    gratitudeComponentState &&
    isValidToShowGratitudeComponent({
      gratitudeComponentState: gratitudeComponentState,
      country,
      isCustomerService: isCustomerService(applicationId),
    });
  // It is expected to be missing a schedule when we have the ShowShiftSelection gratitude page
  const ignoreApplicationError = gratitude && appError?.errorCode === "SCHEDULE_DETAILS_NOT_FOUND";

  // Currently, we are updating this error in Redux only for application data and job data hook calls
  if (appError && !ignoreApplicationError) {
    recordRumEvent({
      type: "ash_error_application_error",
      errorCode: appError.errorCode,
      errorMessage: appError.errorMessage,
      errorStatusCode: appError.errorStatusCode,
    });
    return <ApplicationErrorBanner />;
  }

  if (!appConfigLoaded || !gratitudeComponentState || !isConfigLoaded) {
    return <AppLoader />;
  }

  if (gratitude) {
    return <Gratitude gratitudeComponentState={gratitudeComponentState} stage={stage} />;
  }

  if (isNewHireVideoRoute) {
    return <NewHireVideoFlyout isTask={false} />;
  }

  const enableScreeningResultsComponent =
    applicationManageData &&
    validStatesToShowScreeningResultForHBAApplications(
      applicationId,
      country,
      isAlumniCandidate,
      applicationManageData?.applicationState
    );

  return (
    <Col id={"checklist-main-page"} backgroundColor={HVH_COLORS.NEUTRAL_10} data-testid="new-ui">
      {(isAlumniCandidate || skipAppointmentOneEligiblilty) && <AlumniMessageBanner />}
      {<ApplicationErrorBanner />}
      {isSmallViewport && isDragonStonePage && (
        <Col alignItems="center">{appConfigLoaded && <ManageApplicationCard stage={stage} collapsed={true} />}</Col>
      )}
      <Col id={"page-header"} gridGap="2rem">
        {isDragonStonePage && (
          <Col alignItems="left">
            <AppointmentCard stage={stage} />
          </Col>
        )}
      </Col>
      <Col id={"task-group-header"} alignItems="center">
        <Col
          color={HVH_COLORS.NEUTRAL_90}
          maxWidth={isLargeViewport ? "1500px" : "900px"}
          width={"100%"}
          gridGap="2rem"
          padding={isLargeViewport ? "1rem 1rem 4rem 1rem" : "0rem 0rem 0rem 0rem"}
        >
          <Flex
            flexDirection={isLargeViewport ? "row" : "column"}
            justifyContent={"space-evenly"}
            gridGap={isLargeViewport ? "1rem" : "0rem"}
          >
            <Col width={isLargeViewport ? "60%" : "100%"}>
              {stage ? (
                <TaskGroupAggregator
                  groups={stage?.startsWith("gamma") ? newTaskGroups : groups}
                  // groups={newTaskGroups}
                  candidateId={candidateId}
                  stage={stage}
                />
              ) : null}
            </Col>
            <Col width={isLargeViewport ? "40%" : "100%"}>
              {appConfigLoaded && isDragonStonePage && !isSmallViewport && enableScreeningResultsComponent && (
                <ScreeningResults country={country} />
              )}
              {appConfigLoaded && isDragonStonePage && !isSmallViewport && (
                <ManageApplicationCard stage={stage} collapsed={false} />
              )}
            </Col>
          </Flex>
        </Col>
      </Col>
      {appConfigLoaded && isDragonStonePage && isSmallViewport && enableScreeningResultsComponent && (
        <Col padding="0rem 0rem 4rem 0rem">
          <ScreeningResults country={country} />
        </Col>
      )}
    </Col>
  );
};
