import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { NHEAppointmentStatusEnum } from "../components/appointment-card/card-controlled";
import { CAAS_STATUS } from "../components/i9-task/i9-data";
import { updateStatusForAshLite } from "../components/task";
import {
  HIRED_SF_STEP_SUBSTEP,
  SUPPORTED_COUNTRY,
  NHE_INCOMPLETE_APPLICATION_STATE,
  NOT_TENTATIVE_START_APPLICATION_STATES,
  RIGHT_TO_WORK_TASK_DEFINITION_ID,
} from "../config/appConfigConstants";
import { GratitudeStateEnum, GratitudeStateType } from "../hooks/useGratitudeComponentState.hook";
import {
  getTaskDefinitionsFromConfig,
  isCustomerService,
  isRescheduleAllowedPastApptTimeByAppConfig,
} from "../utility/app-config-helper";
import { ApplicationManageData, JobDetailsModel, RightToWorkResponse } from "../utility/application-data";
import { APPLICATION_STATE, SF_APP_STATE } from "../utility/constants/application-status";
import { isSalesforceApplication } from "./url-params";
import { setupSdaPayloadFromJobDetail } from "./utils";
import moment from "moment-timezone";
import { UnsoundEither } from "../utility/generic-types";

export const isEligibleToDisplayGratitudeComponent = (
  country: SUPPORTED_COUNTRY,
  gratitudeComponentState: GratitudeStateType,
  applicationId: string
) => {
  return (
    gratitudeComponentState !== GratitudeStateEnum.HideGratitudeComponent &&
    country === SUPPORTED_COUNTRY.GB &&
    !isCustomerService(applicationId)
  );
};

export const getRtwTaskDefinition = (applicationId: string) =>
  getTaskDefinitionsFromConfig(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId);

export const isUkRtwNoCaasIntegration = (applicationId: string) => {
  const rtwTaskDefinition = getRtwTaskDefinition(applicationId);
  const isIntegratedWithCaaS = !rtwTaskDefinition || rtwTaskDefinition.isIntegratedWithCaaS;
  return (
    updateStatusForAshLite(isIntegratedWithCaaS, applicationId, undefined, rtwTaskDefinition) ===
    CAAS_STATUS.UkRtwNoCaasIntegration
  );
};

export const isEligibleForCSS = (
  applicationId: string,
  applicationManageDataResult: ApplicationManageData | undefined
) => {
  if (!isSalesforceApplication(applicationId)) {
    if (applicationManageDataResult?.enableCSS) {
      return true;
    }
  }

  return false;
};

export const isEligibleForWithdraw = (
  applicationId: string,
  applicationManageDataResult: ApplicationManageData | undefined
) => {
  if (!isSalesforceApplication(applicationId)) {
    return !applicationManageDataResult?.hideWithdrawApplication;
  }

  return false;
};

export const isEligibleForDrugTestRetakeAppointment = (
  drugTestAppointmentDetails: UnsoundEither<AppointmentInfo, { errorStatusCode?: number }> | undefined,
  applicationManageDataResult: ApplicationManageData | undefined,
  isSalesforceApplication: boolean
) => {
  if (isSalesforceApplication) return false;
  const now = moment();

  const isStartTimeInPast =
    drugTestAppointmentDetails?.startTimestamp && moment(drugTestAppointmentDetails.startTimestamp).isBefore(now);

  return !!(
    drugTestAppointmentDetails?.status === NHEAppointmentStatusEnum.RESERVED &&
    applicationManageDataResult?.applicationState &&
    applicationManageDataResult.applicationState === "CONTINGENCIES_PENDING" &&
    !isStartTimeInPast
  );
};

export const isEligibleForNHEAppointmentRescheduling = (
  nheAppointmentResult: UnsoundEither<AppointmentInfo, { errorStatusCode?: number }> | undefined,
  applicationManageDataResult: ApplicationManageData | undefined,
  isSalesforceApplication: boolean
) => {
  if (isSalesforceApplication) return false;
  const now = moment();

  const isStartTimeInPast =
    nheAppointmentResult?.startTimestamp && moment(nheAppointmentResult.startTimestamp).isBefore(now);

  //Allowing candidates to reschedule the NHE appointments even after appointment time is in past
  if (isRescheduleAllowedPastApptTimeByAppConfig()) {
    return !!(
      nheAppointmentResult?.status === NHEAppointmentStatusEnum.RESERVED &&
      applicationManageDataResult?.applicationState &&
      NHE_INCOMPLETE_APPLICATION_STATE.includes(applicationManageDataResult.applicationState)
    );
  } else {
    return !!(
      nheAppointmentResult?.status === NHEAppointmentStatusEnum.RESERVED &&
      applicationManageDataResult?.applicationState &&
      NHE_INCOMPLETE_APPLICATION_STATE.includes(applicationManageDataResult.applicationState) &&
      !isStartTimeInPast
    );
  }
};

export const isEligibleForRTWAppointmentRescheduling = (
  rtwAppointmentResult: UnsoundEither<AppointmentInfo, { errorStatusCode?: number }> | undefined,
  applicationManageDataResult: ApplicationManageData | undefined,
  isSalesforceApplication: boolean
) => {
  if (isSalesforceApplication) return false;
  const now = moment();

  const isStartTimeInPast =
    rtwAppointmentResult?.startTimestamp && moment(rtwAppointmentResult.startTimestamp).isBefore(now);

  return !!(
    rtwAppointmentResult?.status === NHEAppointmentStatusEnum.RESERVED &&
    applicationManageDataResult?.applicationState &&
    !isStartTimeInPast
  );
};

export const getRtwAppointmentInfo = (country: SUPPORTED_COUNTRY, caasRTWStatus: RightToWorkResponse | undefined) => {
  if (country === SUPPORTED_COUNTRY.GB) {
    const rtwAppointmentProps = {
      isRtwAppointmentEnabled: caasRTWStatus?.rightToWork?.status === CAAS_STATUS.PendingF2FCheck,
      PreviousrightToWorkExpired: caasRTWStatus?.rightToWork?.PreviousrightToWorkExpired || false,
    };

    return rtwAppointmentProps;
  }

  return undefined;
};

export const resolveSdaResponse = (scheduleDetails: JobDetailsModel | undefined | null) => {
  return setupSdaPayloadFromJobDetail(scheduleDetails);
};

export const isTentativeStartDateByApplicationState = (
  scheduleDetails: JobDetailsModel,
  applicationManageData: ApplicationManageData
) => {
  const { applicationState, applicationStepSubStep } = applicationManageData;
  const isSDAStartDateTentative = scheduleDetails?.startDateStatus === "TENTATIVE";

  // possible null if applicationState is come from SalesForce
  if (applicationState) {
    const isApplicationStateTentative = !NOT_TENTATIVE_START_APPLICATION_STATES.includes(
      applicationState as APPLICATION_STATE
    );
    return isApplicationStateTentative && isSDAStartDateTentative;
  } else if (applicationStepSubStep) {
    const isApplicationStateTentative = !HIRED_SF_STEP_SUBSTEP.includes(applicationStepSubStep as SF_APP_STATE);
    return isApplicationStateTentative;
  }

  return isSDAStartDateTentative;
};
